html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    user-select: none;
}

h1,
h2,
p,
a,
li {
    margin: 0;
    padding: 0;
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

button {
    margin: 0;
    padding: 0;
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    cursor: pointer;
}

#frame {
    width: 100%;
    height: 100%;
    position: relative;

    header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 20px;
        display: flex;
        justify-content: flex-end;

        .timer {
            margin: 0;
            padding: 0;
            color: #212121;
            font-size: 18px;
            letter-spacing: 3px;
        }
    }

    canvas {
        display: block;
        width: 100%;
        height: 100%;
    }

    footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .version {
            margin: 0;
            padding: 0;
            color: white;
            font-size: 14px;
            letter-spacing: 3px;
            text-decoration: underline;
        }

        .actions {
            display: flex;
            gap: 24px;
            align-items: center;
            justify-content: center;

            .btn-fast-forward {
                width: 50px;
                height: 50px;
                color: #f0f0f0;
                border-radius: 50%;
                border: 1px solid #f0f0f0;
                background-color: #212121;
                transition: 300ms;

                &:hover {
                    box-shadow: 0 0 14px #212121;
                    transition: 300ms;
                }
            }

            .btn-today {
                padding: 12px;
                color: #f0f0f0;
                border-radius: 8px;
                border: 1px solid #f0f0f0;
                background-color: #212121;
            }
        }
    }
}