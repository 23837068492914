html,
body,
#root {
    overflow: auto;
}

#versions {
    padding: 32px;
    min-height: 100vh;
    background-color: rgba(255, 223, 186, 0.3);

    .home {
        display: inline-block;
        color: black;
        margin-bottom: 16px;
        font-size: 14px;
    }

    .main-heading {
        margin-bottom: 32px;
        font-size: 22px;
    }

    .versions {
        display: flex;
        flex-direction: column;
        gap: 24px;
        color: rgba(0, 0, 0, 0.6);

        .version-item {
            padding: 16px;
            color: rgba(0, 0, 0, 0.87);
            box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
            background-color: rgba(255, 207, 160, 0.10);
            border-radius: 8px;

            .heading {
                margin-bottom: 10px;

                .status {
                    font-size: 14px;
                    font-weight: 700;
                }
            }

            .datetime {
                margin-bottom: 16px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.5);
            }

            .description {

                .added {
                    padding: 0;
                    list-style-type: none;

                    li {
                        margin-bottom: 16px;
                        line-height: 1.5;
                    }

                    li::before {
                        content: '+';
                        padding: 0 8px;
                        color: green;
                        font-weight: bold;
                    }
                }
            }

            .view {
                margin-top: 20px;
                display: inline-block;
                box-sizing: border-box;
                -webkit-tap-highlight-color: transparent;
                cursor: pointer;
                user-select: none;
                vertical-align: middle;
                appearance: none;
                font-family: Roboto, Helvetica, Arial, sans-serif;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.75;
                letter-spacing: 0.02857em;
                text-transform: uppercase;
                min-width: 64px;
                color: rgb(255, 255, 255);
                background-color: rgb(0, 141, 89);
                box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
                outline: 0px;
                border-width: 0px;
                border-style: initial;
                border-color: initial;
                border-image: initial;
                text-decoration: none;
                padding: 6px 16px;
                border-radius: 4px;
            }

        }
    }
}